import React from "react"
import { Layout } from "../components/Layout"

export default function NotFound() {
  return (
    <Layout>
      <h2>404</h2>
      <p>Sorry, that page does not exist</p>
    </Layout>
  )
}
